<template>
  <div class="split-wrap">
    <div
      v-for="item in wordList"
      :key="item + Math.random()">
      {{ item }}
    </div>
  </div>
</template>

<script>
  export default {
    name:'WordSplit',
    props: {
      'sentence': {
        type: String,
        default: ''
      }
    },
    data(){
      return {
        wordList: []
      }
    },
    mounted() {
      // console.log('分割的句子', this.sentence)
      this.wordList = this.sentence.split(' ')
      // console.log('分割后的单词数组', this.wordList)
    }
  }
</script>

<style lang="scss" scoped>
.split-wrap {
      display: flex;
      justify-content: space-between;
}

</style>
